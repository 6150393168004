import { SurveyEntity } from '../../../cdk/JWebSurveyApp/lib/lambda_functions/apis/surveys/SaveSurveysRequestValidator';
import { UpdateSurveyEntity } from '../../../cdk/JWebSurveyApp/lib/lambda_functions/apis/surveys/SaveSurveysRequestValidator';
import { ApiConfigure } from '../ApiConfigure';

/**
 * Commnad Params
 */
interface IBaseCommandParam {}

interface IListSurveyParam extends IBaseCommandParam {}

interface ISaveSurveyParam extends IBaseCommandParam {
    body: UpdateSurveyEntity | SurveyEntity;
}

interface IDeleteSurveyParam extends IBaseCommandParam {
    surveyId: string;
}

/**
 * Commands
 */
export interface IApiCommand<Response> {
    execute(apiConfigure: ApiConfigure): Promise<Response>;
}

export class ListSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/surveys';
    private params: string;

    constructor(param: IListSurveyParam) {
        this.params = '';
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'GET',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}

export class SaveSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)

    private PATH_NAME = '/surveys';
    private body: SurveyEntity | UpdateSurveyEntity;

    constructor(param: ISaveSurveyParam) {
        this.body = param.body;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // Request body
        const body = this.body;

        // config 構築
        const config: RequestInit = {
            method: 'PUT',
            ...apiConfigure.fetchConfig,
            body: JSON.stringify(body),
        };
        console.log('config', config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME;
        return await fetch(url, config);
    }
}

export class DeleteSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/surveys';
    private params: string;

    constructor(param: IDeleteSurveyParam) {
        this.params = `?survey_id=${param.surveyId}`;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'DELETE',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}
