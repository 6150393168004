// AuthContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import * as Auth from 'aws-amplify/auth';
import { LoadingModal } from '../../components/ui-elements/UiElements';

interface AuthProviderProps {
    children: ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [idToken, setIdToken] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        checkCurrentUser();
    }, []);

    async function checkCurrentUser() {
        setLoading(true);
        try {
            const currentUser = await Auth.getCurrentUser();
            console.log(currentUser);
            if (currentUser) {
                console.log('ログイン済み');
                const idToken = await getIdToken();
                setIdToken(idToken);
                setIsSignedIn(true);
            } else {
                console.log('ログインしていません');
                setIsSignedIn(false);
                setIdToken(undefined);
            }
        } catch (error) {
            console.log('ログインしていません');
        } finally {
            setLoading(false);
        }
    }

    // private function
    async function getIdToken() {
        const session = await Auth.fetchAuthSession();
        if (session) {
            if (session.tokens) {
                const { idToken } = session.tokens;
                return idToken?.toString();
            } else {
                throw Error('token is null');
            }
        } else {
            throw Error('session is null');
        }
    }

    const signIn = async (
        username: string,
        password: string,
        admincheck: boolean,
        doNavigate: (path: string) => void,
    ) => {
        setLoading(true);
        try {
            console.log('サインイン開始');
            const { isSignedIn, nextStep } = await Auth.signIn({ username, password });
            console.log({ isSignedIn, nextStep });

            if (nextStep.signInStep === 'DONE') {
                console.log('サインイン成功！');
            } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                console.log('パスワード変更が必要。');
                // NOTE: admin に遷移していいか？
                // 通常盤でも change_password を用意する必要はないか？
                doNavigate('/admin/change_password');
                return;
            } else {
                throw Error('Unexpected signInStep > ' + nextStep.signInStep);
            }

            //
            console.log('ログイン完了処理');
            const idToken = await getIdToken();
            setIsSignedIn(true);
            setIdToken(idToken);
            console.log(isSignedIn, idToken);
            if (admincheck) {
                doNavigate('/admin/surveys');
            } else {
                doNavigate('/surveys');
            }
        } catch (error) {
            alert('サインイン失敗: ' + error);
        } finally {
            setLoading(false);
        }
    };

    const signOut = async (
        //
        doNavigate: (path: string) => void,
    ) => {
        setLoading(true);
        try {
            console.log('サインアウト開始');
            await Auth.signOut();

            setIsSignedIn(false);
            setIdToken(undefined);
            alert('サインアウトしました。');
            doNavigate('/signin');
        } catch (error) {
            alert('サインアウトに失敗しました。' + error);
        } finally {
            setLoading(false);
        }
    };

    const changePassword = async (
        username: string,
        oldPassword: string,
        newPassword: string,
        doNavigate: (path: string) => void,
    ) => {
        setLoading(true);
        try {
            // 一旦ユーザーをサインインさせる
            console.log('サインイン');
            const { isSignedIn, nextStep } = await Auth.signIn({ username, password: oldPassword });
            console.log({ isSignedIn, nextStep });

            console.log('パスワード変更開始');
            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                console.log('初回パスワード変更');
                const { isSignedIn, nextStep } = await Auth.confirmSignIn({
                    challengeResponse: newPassword,
                });
                console.log('confirmSignIn', { isSignedIn, nextStep });
            } else if (nextStep.signInStep === 'DONE') {
                console.log('サインイン済みユーザーのパスワード変更処理');
                await Auth.updatePassword({ oldPassword, newPassword });
            } else {
                throw Error('Unexpected signInStep > ' + nextStep.signInStep);
            }
            // 状態更新
            const idToken = await getIdToken();
            setIsSignedIn(true);
            setIdToken(idToken);

            alert('パスワードが変更されました。');
            doNavigate('/');
        } catch (error) {
            console.error(error);
            alert('パスワード変更に失敗しました: ' + error);
        } finally {
            setLoading(false);
        }
    };

    // const resetPassword = async (
    //     username: string,
    //     code: string,
    //     newPassword: string,
    //     doNavigate: (path: string) => void,
    // ) => {
    //     setLoading(true);
    //     try {
    //         console.log('パスワード再設定');
    //         Auth.forgotPasswordSubmit(username, code, newPassword);
    //         alert('パスワードが再設定されました。');
    //         doNavigate('/');
    //     } catch (error) {
    //         console.error(error);
    //         alert('パスワード再設定に失敗しました: ' + error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <AuthContext.Provider
            value={{
                loading,
                isSignedIn,
                idToken,
                setIsSignedIn,
                signIn,
                signOut,
                changePassword,
                // resetPassword,
            }}
        >
            <LoadingModal open={loading} admin={false} />
            {!loading && children}
        </AuthContext.Provider>
    );
};

interface AuthContextProps {
    loading: boolean;
    isSignedIn: boolean;
    idToken: string | undefined;
    setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
    signIn: (
        username: string,
        password: string,
        admincheck: boolean,
        doNavigate: (path: string) => void,
    ) => void;
    signOut: (
        //
        doNavigate: (path: string) => void,
    ) => void;
    changePassword: (
        username: string,
        oldPassword: string,
        newPassword: string,
        doNavigate: (path: string) => void,
    ) => void;
    // resetPassword: (
    //     username: string,
    //     code: string,
    //     newPassword: string,
    //     doNavigate: (path: string) => void,
    // ) => void;
}
export const AuthContext = createContext<AuthContextProps | null>(null);

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined || context === null) {
        throw new Error('useAuth must be used within a StatusProvider');
    }
    return context;
}
