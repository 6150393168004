import React from 'react';
import { NavLink } from 'react-router-dom';
import { FilePlusIcon, ListBulletIcon } from '@radix-ui/react-icons';

const AdminSideBar = () => {
    return (
        <div
            className={`fixed left-0 z-50 h-screen w-52 
          bg-adminbasecolor text-white`}
        >
            <img src="/survey2.png" alt="ロゴ" className=" mx-auto mt-4 w-1/2" />
            <p className="text-center text-3xl font-bold">PICK ONE</p>

            <div className="mt-10 flex w-full flex-col  text-xl ">
                <NavLink
                    to="/admin/surveys"
                    className={({ isActive }) => {
                        return isActive
                            ? ' bg-secondcolor py-2  pl-4 font-bold text-adminbasecolor'
                            : ' bg-adminbasecolor py-2 pl-4 font-bold text-secondcolor hover:bg-secondcolor hover:text-adminbasecolor';
                    }}
                >
                    <div className="flex items-center ">
                        <div className="mr-2 scale-125">
                            <FilePlusIcon />
                        </div>
                        <p>投票の作成</p>
                    </div>
                </NavLink>
                <NavLink
                    to="/admin/survey_list"
                    className={({ isActive }) => {
                        return isActive
                            ? ' bg-secondcolor py-2  pl-4 font-bold text-adminbasecolor'
                            : ' bg-adminbasecolor py-2 pl-4 font-bold text-secondcolor hover:bg-secondcolor hover:text-adminbasecolor';
                    }}
                >
                    <div className="flex items-center  ">
                        <div className="mr-2  scale-125 ">
                            <ListBulletIcon />
                        </div>
                        <p> 投票一覧</p>
                    </div>
                </NavLink>
                {/* <NavLink
                    to="/signout"
                    className={({ isActive }) => {
                        return isActive
                            ? ' bg-secondcolor py-2  pl-4 font-bold text-adminbasecolor'
                            : ' bg-adminbasecolor py-2 pl-4 font-bold text-secondcolor hover:bg-secondcolor hover:text-adminbasecolor';
                    }}
                >
                    サインアウト
                </NavLink> */}
            </div>
        </div>
    );
};

export default AdminSideBar;
