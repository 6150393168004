import React from 'react';

const AdminTitleBar = () => {
    return (
        <div className="sticky top-0 flex h-auto flex-col items-center justify-center bg-adminbasecolor py-6 ">
            <div className="z-50 rounded-lg bg-adminbasecolor bg-opacity-50">
                <div className="z-50 flex flex-row  items-center justify-center ">
                    <img src="/survey2.png" alt="logo" className=" h-12" />
                    <p className="flex items-center pl-4 text-4xl font-bold text-white">PICK ONE</p>
                </div>
                <p className="z-50 mt-4 text-xl text-white ">
                    投票サービス"PICK ONE"を使うと、既に運営されているサイトに
                    <br />
                    質問して回答を集める機能を組み込むことができます。
                </p>
            </div>
            <img src="/top.png" alt="top" className="  absolute bottom-0 right-10 h-full pt-4" />
        </div>
    );
};

export default AdminTitleBar;
