import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../features/auth/AuthContext';

import AdminTitleBar from '../../components/layouts/AdminTitleBar';

const specialChars = '^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\\\ , > < \' : ; | _ ~ ` + =';

const ChangePassword = () => {
    const [username, setUsername] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const navigate = useNavigate();
    const { changePassword } = useAuth();

    // const inputStyle = {
    //     margin: '0 0 20px 0', // 下にマージンを設ける
    //     padding: '10px', // パディング
    //     border: '1px solid #ccc', // ボーダー
    //     borderRadius: '4px', // 角丸
    // };

    return (
        <>
            <AdminTitleBar />
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '400px', // フォームの幅を固定
                    margin: '0 auto', // 中央揃え
                }}
            >
                <h3 className="py-3 text-xl font-bold">パスワード変更</h3>
                <label htmlFor="username">ユーザー名</label>
                <input
                    className="mb-2 w-full rounded-lg border-2 px-1 py-2 required:pl-2 valid:pl-2 focus:border-adminbasecolor  focus:bg-yellow-50 focus:outline-none"
                    id="username"
                    name="username"
                    type="text"
                    placeholder="ユーザー名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor="oldPassword">現在のパスワード</label>
                <input
                    className="mb-2 w-full rounded-lg border-2 px-1 py-2 required:pl-2 valid:pl-2 focus:border-adminbasecolor  focus:bg-yellow-50 focus:outline-none"
                    id="oldPassword"
                    name="oldPassword"
                    type="password"
                    placeholder="現在のパスワード"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <label htmlFor="newPassword">新しいパスワード</label>
                <ul
                    style={{
                        fontSize: '12px',
                        marginTop: '5px',
                        // marginBottom: "20px",
                        color: 'grey',
                    }}
                >
                    <li>パスワードは 16 文字以上必要です。</li>
                    <li>少なくとも1つの数字を含む必要があります。</li>
                    <li>
                        少なくとも1つの特殊文字を含む必要があります。(次の文字は特殊文字としてカウントされます。)
                        <br />
                        {specialChars}
                    </li>
                    <li>少なくとも1つの大文字を含む必要があります。</li>
                    <li>少なくとも1つの小文字を含む必要があります。</li>
                </ul>
                <input
                    className="mb-2 w-full rounded-lg border-2 px-1 py-2 required:pl-2 valid:pl-2 focus:border-adminbasecolor  focus:bg-yellow-50 focus:outline-none"
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    placeholder="新しいパスワード"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                    className="mx-auto mt-2 w-52 rounded-full bg-adminbasecolor p-2 text-white hover:border-2 hover:border-adminbasecolor hover:bg-white hover:text-adminbasecolor"
                    type="button" // formを送信しないようにする
                    onClick={() => {
                        changePassword(username, oldPassword, newPassword, () => {
                            console.log('navigate');
                            navigate('/admin');
                        });
                    }}
                >
                    パスワード変更
                </button>
            </form>
        </>
    );
};

export default ChangePassword;
