import React, { useState, useEffect } from 'react';
import { useAuth } from '../../features/auth/AuthContext';
import { useJWebApi } from '../../features/api/useJWebApi';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { convertUtcToJst16 } from '../../utils/DateTimeManager';
import {
    DeleteSurveyCommand,
    ListSurveyCommand,
} from '../../features/api/surveys/SurveysApiCommands';
import {
    SurveyEntity,
    OptionKey,
} from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/surveys/SaveSurveysRequestValidator';
import { DeleteConfirmModal, LoadingModal } from '../../components/ui-elements/UiElements';
import { Tooltip } from 'react-tooltip';
import { CopyIcon } from '@radix-ui/react-icons';
import ResultPage from '../result/ResultPage';
import AdminSideBar from '../../components/layouts/AdminSideBar';
import AdminSignIn from '../signin/AdminSignIn';
export type OptionsRecord = Record<OptionKey, string>;

const AdminSurveyListPage = () => {
    document.body.style.overflow = 'hidden';
    const admin = true;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [copy, setCopy] = useState('投票IDをコピー');

    //ステータス表示
    const [nowTime, setNowTime] = useState('');
    useEffect(() => {
        const getCurrentTime = () => {
            const now = new Date();
            const japanOffset = 9 * 60;
            const japanTime = new Date(now.getTime() + japanOffset * 60 * 1000);
            const iso8601Time = japanTime.toISOString();
            setNowTime(iso8601Time);
        };
        getCurrentTime();
        const intervalId = setInterval(() => {
            getCurrentTime();
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    //API
    const { idToken } = useAuth();
    const apiConfigure = new ApiConfigure({
        authToken: idToken,
    });
    const { response, loading, call } = useJWebApi(apiConfigure);
    const { response: resDel, loading: delLoading, call: deleteCall } = useJWebApi(apiConfigure);

    const [isUpdate, setIsUpdate] = useState<number>(0);
    const handleListUpdate = () => {
        setIsUpdate(isUpdate + 1);
    };

    useEffect(() => {
        if (idToken) {
            call(new ListSurveyCommand({}));
        }
    }, [idToken, resDel, isUpdate]);

    function sendDelete(id?: string) {
        if (id) {
            deleteCall(new DeleteSurveyCommand({ surveyId: id }));
        }
    }

    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [deleteTargetId, setDeleteTargetId] = useState<string | undefined>();
    const surveys: SurveyEntity[] = response?.data.surveys;
    const [isSurveyId, setSurveyId] = useState<string | undefined>();

    const handleItemClick = (surveyId: string | undefined) => {
        setIsModalOpen(true);
        setSurveyId(surveyId);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
    };

    const handleDeleteCloseClick = () => {
        setOpenConfirm(false);
        closeModal();
    };
    const handleModalOnlyClose = () => {
        setOpenConfirm(false);
    };
    const handleDeleteOpenClick = (id: string | undefined) => {
        setOpenConfirm(true);
        setDeleteTargetId(id);
    };

    const handleCopyClick = () => {
        setCopy('クリップボードにコピーしました');
    };
    const handleMouseOut = () => {
        setTimeout(() => {
            setCopy('投票IDをコピー');
        }, 200);
    };

    //キャンセル
    const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
    const handleCancelModal = () => {
        setIsOpenCancelModal(true);
    };
    const handleCancelModalClose = () => {
        setIsOpenCancelModal(false);
    };

    const { isSignedIn } = useAuth();
    if (!isSignedIn) {
        return <AdminSignIn />;
    }
    return (
        <>
            <LoadingModal open={loading || delLoading} admin={true} />

            <div className="flex h-screen  overflow-hidden">
                <div className="flex w-full items-center justify-center">
                    <AdminSideBar />
                    <div className={`ml-52 h-full w-full p-10`}>
                        <LoadingModal open={loading || delLoading} admin={true} />
                        <DeleteConfirmModal
                            open={openConfirm}
                            admin={admin}
                            handleConfirm={() => {
                                sendDelete(deleteTargetId);
                            }}
                            handleClose={handleDeleteCloseClick}
                            handleModalOnlyClose={handleModalOnlyClose}
                        />
                        <label className="ml-6 text-2xl font-bold">投票一覧</label>
                        <>
                            {response?.data && (
                                <div>
                                    <ResultPage
                                        isOpen={isModalOpen}
                                        handleDeleteConfilmOpen={handleDeleteOpenClick}
                                        onClose={closeModal}
                                        surveyId={isSurveyId}
                                        admin={admin}
                                        isOpenCancelModal={isOpenCancelModal}
                                        handleListUpdate={handleListUpdate}
                                        handleCancelModal={handleCancelModal}
                                        handleCancelModalClose={handleCancelModalClose}
                                    />

                                    <div className=" m-4 h-85% overflow-y-auto  rounded-xl bg-white shadow-lg">
                                        <table className={'w-full font-thin shadow-md'}>
                                            <thead className=" sticky top-0">
                                                <tr className=" border-b-2 bg-white  text-left ">
                                                    <th className="px-4 py-2 "></th>
                                                    <th className=" py-2 pl-6 ">
                                                        <p>投票ID</p>
                                                    </th>

                                                    <th className=" py-2 pl-8">
                                                        <p>質問</p>
                                                    </th>
                                                    <th className=" py-2 pl-6">
                                                        <p>選択肢リスト</p>
                                                    </th>
                                                    <th className=" py-2 pl-6">
                                                        <p>説明</p>
                                                    </th>
                                                    <th className="py-2 pl-6">
                                                        <p>開始時間</p>
                                                    </th>
                                                    <th className="px-6 py-2">
                                                        <p>終了時間</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {surveys
                                                    .sort((b, a) => {
                                                        if (a.createdAt === undefined) return 1;
                                                        if (b.createdAt === undefined) return -1;
                                                        return a.createdAt.localeCompare(
                                                            b.createdAt,
                                                        );
                                                    })
                                                    .map((row: SurveyEntity, index) => (
                                                        //レコード
                                                        <tr
                                                            key={row.id}
                                                            onClick={() => handleItemClick(row.id)}
                                                            className=" cursor-pointer border-b-2 leading-relaxed hover:bg-yellow-50"
                                                        >
                                                            {/* 状態 */}
                                                            <td className="whitespace-nowrap py-1 pl-4 text-sm ">
                                                                <div
                                                                    className={`rounded-full px-2 py-1 text-center font-bold shadow ${
                                                                        nowTime <
                                                                        convertUtcToJst16(
                                                                            row.startDateTime,
                                                                        )
                                                                            ? 'bg-basecolor text-white'
                                                                            : convertUtcToJst16(
                                                                                    row.endDateTime,
                                                                                ) < nowTime
                                                                              ? 'bg-gray-400 text-white'
                                                                              : 'bg-red-400 text-white'
                                                                    }`}
                                                                >
                                                                    {nowTime <
                                                                    convertUtcToJst16(
                                                                        row.startDateTime,
                                                                    )
                                                                        ? '期間前'
                                                                        : convertUtcToJst16(
                                                                                row.endDateTime,
                                                                            ) < nowTime
                                                                          ? '期間終了'
                                                                          : '期間中'}
                                                                </div>
                                                            </td>
                                                            {/* 投票ID */}
                                                            <td>
                                                                <div className="flex">
                                                                    <p className="ml-6 flex-auto ">
                                                                        {row.id}
                                                                    </p>
                                                                    <div
                                                                        onClick={handleButtonClick}
                                                                        className="  flex items-center justify-center"
                                                                    >
                                                                        <button
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(
                                                                                    row.id ||
                                                                                        'Default Text',
                                                                                );
                                                                                handleCopyClick();
                                                                            }}
                                                                            data-tooltip-id="copy"
                                                                            onMouseOut={
                                                                                handleMouseOut
                                                                            }
                                                                            className=""
                                                                        >
                                                                            <div className=" text-gray-400">
                                                                                <CopyIcon />
                                                                            </div>
                                                                        </button>
                                                                        <Tooltip
                                                                            id="copy"
                                                                            place="top-end"
                                                                            content={copy}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* 質問 */}
                                                            <td className="pl-8 ">
                                                                <p>{row.questionBody}</p>
                                                            </td>
                                                            {/* 選択肢リスト */}
                                                            <td className="whitespace-nowrap  pl-12">
                                                                <ol className="list-decimal">
                                                                    {Object.entries(row.options)
                                                                        .sort((a, b) =>
                                                                            a[0].localeCompare(
                                                                                b[0],
                                                                                undefined,
                                                                                { numeric: true },
                                                                            ),
                                                                        )
                                                                        .map(([key, value]) => {
                                                                            return (
                                                                                <p key={key}>
                                                                                    <li>{value}</li>
                                                                                </p>
                                                                            );
                                                                        })}{' '}
                                                                </ol>
                                                            </td>
                                                            {/* 説明 */}
                                                            <td className="pl-6">
                                                                <label>{row.description}</label>
                                                            </td>

                                                            {/* 開始時間～終了時間 */}
                                                            <td className="whitespace-nowrap pl-6">
                                                                {convertUtcToJst16(
                                                                    row.startDateTime,
                                                                )
                                                                    .replace('T', ' ')
                                                                    .replace(/-/g, '/')}
                                                            </td>
                                                            <td className="whitespace-nowrap px-6">
                                                                {convertUtcToJst16(row.endDateTime)
                                                                    .replace('T', ' ')
                                                                    .replace(/-/g, '/')}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminSurveyListPage;
