import { useState } from "react";
import { IApiCommand } from "./surveys/SurveysApiCommands";
import { ApiConfigure } from "./ApiConfigure";
import { convertErrorToReject } from "./ApiUtils";
import { TIME_OUT_MS } from "../../constants";
import { IFullfill, IReject } from "../../types/global";

export function useJWebApi(apiConfigure: ApiConfigure) {
  const [response, setResponse] = useState<IFullfill | null>(null);
  const [error, setError] = useState<IReject | null>(null);
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());

  // private
  let isTimeout = false;

  const call = async (command: IApiCommand<Response>) => {
    // 状態初期化
    setLoading(true);
    setError(null);
    setResponse(null);

    // Client side time out 設定
    const timer = window.setTimeout(() => {
      isTimeout = true;
      controller.abort();
    }, TIME_OUT_MS);

    // signal 追加
    apiConfigure.fetchConfig = {
      ...apiConfigure.fetchConfig,
      signal: controller.signal,
    };
    console.log("apiConfigure", apiConfigure);

    try {
      // console.log('command', command);
      // execute からの例外はサーバーにも届いていない
      const result = await command.execute(apiConfigure);
      console.log("result", result, command);

      // Json で必ずしも返ってくるとは限らないので一旦 text で受け取って後で json に parse する。
      const resultText = await result?.text();

      if (result.ok) {
        // Json に変換する (解析成功していれば、そのまま Json オブジェクトにできる)
        const resultJson: IFullfill = JSON.parse(resultText);
        console.log("resultJson", resultJson);
        setResponse({
          status: result.status,
          statusText: resultJson.statusText,
          data: resultJson.data,
        });
      } else {
        // エラーハンドル
        setError(convertErrorToReject(result, { obj: resultText }));
      }
    } catch (error) {
      // サーバーに到達しないエラーハンドリング
      console.log("aborted?", command, error);
      setError(convertErrorToReject(error, { isTimeout: isTimeout }));
    } finally {
      // Time out 周り
      window.clearTimeout(timer);
      isTimeout = false;
      setController(new AbortController());
      setLoading(false);
    }
  };

  return { response, error, loading, controller, call };
}
