import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    Typography,
} from '@mui/material';

const cancelStyle =
    'm-2 h-10  w-1/2 rounded-full border-2  py-1 text-sm shadow-sm hover:border-yellow-400';

/**
 * ドメインや API に関わらないコンポーネント群
 * コンテキストとは連携する
 */

interface LoadingModalProps {
    open: boolean;
    admin: boolean;
}
export const LoadingModal: React.FC<LoadingModalProps> = ({ open, admin }) => {
    return (
        <Modal
            open={open}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
        >
            {/* Boxコンポーネントをフレックスコンテナとして使用 */}
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex', // フレックスコンテナとして設定
                    alignItems: 'center', // 垂直方向の中心に配置
                    justifyContent: 'center', // 水平方向の中心に配置
                    height: '100%',
                    width: '100%',
                }}
            >
                <div className="flex justify-center" aria-label="読み込み中">
                    <div
                        className={`h-10 w-10 animate-spin rounded-full border-4  border-t-transparent ${admin ? 'border-adminbasecolor' : 'border-basecolor'}`}
                    ></div>
                </div>
            </Box>
        </Modal>
    );
};

interface ConfirmModalProps {
    open: boolean;
    admin: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    handleModalOnlyClose: () => void;
}
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: '10px' } }}>
            <h1 className=" pl-5 pt-4 font-bold">投票の作成</h1>
            <DialogContent>
                <div>
                    <p className=" leading-tight">入力された内容で投票を作成します。</p>
                    <p className=" leading-tight">よろしいですか？</p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 h-10  w-1/2 rounded-full border-2  p-2  py-1 text-sm text-white hover:bg-white  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    作成
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の更新</h1>
            <DialogContent>
                <div>
                    <p className=" leading-tight">入力された内容で更新します。</p>
                    <p className=" leading-tight">よろしいですか？</p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 h-10  w-1/2 rounded-full border-2  p-2  py-1 text-sm text-white hover:bg-white  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    更新
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateCancelModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の更新</h1>
            <DialogContent>
                <div>
                    <p className=" leading-tight">更新された内容を保存せずに終了します。</p>
                    <p className=" leading-tight">よろしいですか？</p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    いいえ
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 h-10  w-1/2 rounded-full border-2  p-2  py-1 text-sm text-white hover:bg-white  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    はい
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const DeleteConfirmModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
    handleModalOnlyClose,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の削除</h1>
            <DialogContent>
                <div>
                    <p className=" leading-tight">
                        投票を削除しますが、よろしいですか？
                        <br />
                        <br />
                        この操作を行った後は、回答結果も含めて <br />
                        投票に関するすべてのデータが失われます。
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleModalOnlyClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 h-10  w-1/2 rounded-full border-2 p-2  py-1 text-sm text-white hover:bg-white  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    削除
                </button>
            </DialogActions>
        </Dialog>
    );
};
interface ResultModalProps {
    open: boolean;
    admin: boolean;
    handleClose: () => void;
    text: string;
}
export const ResultModal: React.FC<ResultModalProps> = ({ open, text, handleClose, admin }) => {
    const [copied, setCopied] = useState<boolean>(false);

    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: '10px' } }}>
            <h1 className=" pl-6 pt-4 font-bold">登録完了</h1>
            <DialogContent>
                <p>投票を作成しました。</p>

                <DialogContentText color="black" className="pl-1 pt-5">
                    投票ID
                </DialogContentText>
                <DialogContentText color="black" className="border-2 pl-3 pr-24">
                    {text}
                </DialogContentText>
                <DialogContentText>
                    <Typography></Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(text);
                        setCopied(true);
                    }}
                    className={` m-2 h-10 w-1/2  rounded-full border-2   py-1 text-sm text-white hover:bg-white  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    {copied ? (
                        <>
                            <CheckIcon />
                            {'コピー完了'}
                        </>
                    ) : (
                        'IDをコピーする'
                    )}
                </button>
                <button onClick={handleClose} className={cancelStyle}>
                    閉じる
                </button>
            </DialogActions>
        </Dialog>
    );
};

interface ResetButtonProps {
    clickHandler: () => void;
}
export const ResetButton: React.FC<ResetButtonProps> = ({ clickHandler }) => {
    return (
        <Button
            onClick={clickHandler}
            type="button"
            variant="contained"
            color="inherit"
            sx={{
                borderRadius: 10,
                padding: '1 2em',
                textTransform: 'none',
                width: '100%',
                boxShadow: 'none',
                backgroundColor: '#F5F7FA',
                '&:hover': {
                    boxShadow: 'none',
                    opacity: 1,
                },
            }}
        >
            リセット
        </Button>
    );
};
