import React from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './features/auth/AuthContext';
import SurveyFormPage from './pages/surveys/SurveyFormPage';
import SurveyListPage from './pages/survey_list/SurveyListPage';
import AdminSurveyFormPage from './pages/surveys/AdminSurveyFormPage';
import AdminSurveyListPage from './pages/survey_list/AdminSurveyListPage';
import SignIn from './pages/signin/SignIn';
import SignOut from './pages/signout/SignOut';
import ChangePassword from './pages/change_password/ChangePassword';

import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig); // Amplifyに設定を適用

const App = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    {/* jweb */}
                    <Route path="/">
                        <Route index element={<SurveyFormPage />} />
                        <Route path="surveys" element={<SurveyFormPage />} />
                        <Route path="survey_list" element={<SurveyListPage />} />
                        <Route path="signin" element={<SignIn />} />
                        <Route path="signout" element={<SignOut />} />
                    </Route>
                    {/* admin */}
                    <Route path="/admin">
                        <Route index element={<AdminSurveyFormPage />} />
                        <Route path="surveys" element={<AdminSurveyFormPage />} />
                        <Route path="survey_list" element={<AdminSurveyListPage />} />
                        <Route path="change_password" element={<ChangePassword />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;
