import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../features/auth/AuthContext';
import TitleBar from '../../components/layouts/TitleBar';

const admincheck: boolean = false;
const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate(); // リダイレクトのためのhook

    const { isSignedIn, signIn } = useAuth();
    if (isSignedIn) {
        navigate('/');
    }
    return (
        <div className="">
            <TitleBar />
            <div className="absolute inset-0 flex items-center justify-center">
                <form>
                    <div className="flex w-screen   flex-col text-lg">
                        <input
                            name="username"
                            type="text"
                            placeholder="ログインID"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="mx-auto  mt-4 w-1/4 rounded-lg  border-2 py-2 valid:pl-2  valid:text-base focus:border-basecolor focus:bg-yellow-50  focus:outline-none"
                        />
                        <input
                            name="password"
                            type="password"
                            placeholder="パスワード"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mx-auto  mt-4 w-1/4 rounded-lg border-2 py-2 valid:pl-2 valid:text-base focus:border-basecolor focus:bg-yellow-50  focus:outline-none"
                        />
                        <button
                            onClick={async () => {
                                await signIn(username, password, admincheck, (path: string) => {
                                    navigate(path);
                                });
                            }}
                            className="mx-auto mt-12 w-52 rounded-full border bg-basecolor p-2 text-white hover:border hover:border-basecolor hover:bg-white hover:text-basecolor"
                        >
                            サインイン
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
